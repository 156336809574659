import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  // 首页
  {
    path: '/home',
    name: 'Home',
    // 路由懒加载：https://router.vuejs.org/zh/guide/advanced/lazy-loading.html
    // webpackPreload：https://www.jianshu.com/p/bbdcfeee7fbc
    component: () => import(/* webpackPreload: true */ '@/views/home'),
    meta: {
      title: '首页',
      showTab: true,
      keepAlive: false
    }
  },
  // 必吃菜品
  {
    path: '/shop/hot',
    name: 'ShopHot',
    component: () => import('@/views/shopHot'),
    meta: {
      title: '必吃菜品',
      showTab: false,
      keepAlive: false
    }
  },
  // 商家信息展示
  {
    path: '/shop/info',
    name: 'ShopInfo',
    component: () => import('@/views/shopInfo'),
    meta: {
      title: '商家信息',
      showTab: false,
      keepAlive: true
    }
  },
  // 商品详情页面
  {
    path: '/product/detail',
    name: 'ProductDetail',
    component: () => import('@/views/productDetail'),
    meta: {
      title: '商品详情',
      showTab: false,
      keepAlive: false
    }
  },
  // 订单列表
  {
    path: '/order/list',
    name: 'OrderList',
    component: () => import('@/views/order'),
    meta: {
      title: '订单列表',
      showTab: true,
      keepAlive: false
    }
  },
  // 订单详情列表
  {
    path: '/order/detail',
    name: 'OrderDetail',
    component: () => import('@/views/orderDetail'),
    meta: {
      title: '订单详情',
      showTab: false,
      keepAlive: false
    }
  },
  // 支付页面
  {
    path: '/pay',
    name: 'PayOrder',
    component: () => import('@/views/pay'),
    meta: {
      title: '订单结算',
      showTab: false,
      keepAlive: true
    }
  },
  // 评价
  {
    path: '/evaluate',
    name: 'Evaluate',
    component: () => import('@/views/evaluate'),
    meta: {
      title: '评价',
      showTab: false,
      keepAlive: false
    }
  },
  // 门店收藏
  {
    path: '/collection',
    name: 'CollectionShop',
    component: () => import('@/views/collection'),
    meta: {
      title: '门店收藏',
      showTab: false,
      keepAlive: false
    }
  },
  // 我的页面
  {
    path: '/my',
    name: 'My',
    component: () => import('@/views/my'),
    meta: {
      title: '我的',
      showTab: true,
      keepAlive: false
    }
  },
  {
    path: '/coupon/list',
    name: 'CouponList',
    component: () => import('@/views/coupon'),
    meta: {
      title: '优惠券列表',
      showTab: false,
      keepAlive: false
    }
  },
  // 地址列表
  {
    path: '/address/list',
    name: 'Address',
    component: () => import('@/views/address'),
    meta: {
      title: '地址列表',
      showTab: false,
      keepAlive: false
    }
  },
  {
    path: '/address/edit',
    name: 'AddressForm',
    component: () => import('@/views/address/addressForm'),
    meta: {
      title: '新增地址',
      showTab: false,
      keepAlive: true
    }
  },
  // 地图页面
  {
    path: '/position/choose',
    name: 'MapPosition',
    component: () => import('@/views/position'),
    meta: {
      title: '选择地址',
      showTab: false,
      keepAlive: true
    }
  },
  // 地图页面
  {
    path: '/address/choose',
    name: 'SelectAddress',
    component: () => import('@/views/SelectAddress'),
    meta: {
      title: '选择地址',
      showTab: false,
      keepAlive: false
    }
  },
  // 登录页面
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login'),
    meta: {
      title: '登录',
      showTab: false,
      keepAlive: false
    }
  },
  // 生成二维码
  {
    path: '/generate/code',
    name: 'GenerateCode',
    component: () => import('@/views/code'),
    meta: {
      title: '二维码',
      showTab: false,
      keepAlive: false
    }
  }
]

const router = new VueRouter({
  scrollBehavior: () => ({ y: 0 }),
  routes
})

export default router
