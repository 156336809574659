import request from '@/utils/request'

// 获取周围地址
export function getPositionAddress(registerUrl, newUserInfo) {
  return request({
    url: registerUrl,
    method: 'post',
    data: newUserInfo
  })
}

// 会员基本信息[简]
export function getInfo() {
  return request({
    url: '/api/user/brief-profile',
    method: 'post'
  })
}

// 注册(暂无注册)
export function registry(data) {
  return request({
    url: '/user/registry',
    method: 'post',
    data
  })
}

// 登录
export function login(data) {
  return request({
    url: '/api/auth/app/login',
    method: 'post',
    data
  })
}
export function refreshLogin(data) {
  return request({
    url: '/api/auth/app/refresh',
    method: 'post',
    data
  })
}
// 重置密码
export function reset(data) {
  return request({
    url: '/user/reset',
    method: 'post',
    data
  })
}

// 新增地址
export function addAddress(data) {
  return request({
    url: '/api/user/address',
    method: 'post',
    data
  })
}

// 删除地址
export function deleteAddress(id) {
  return request({
    url: `/api/user/address/${id}`,
    method: 'delete'
  })
}

// 更新地址
export function updateAddress(id, data) {
  return request({
    url: `/api/user/address/${id}`,
    method: 'put',
    data
  })
}

// 获取地址
export function getAddress(params) {
  return request({
    url: '/user/address',
    method: 'get',
    params
  })
}

// 获取地址列表
export function getAddressList(params) {
  return request({
    url: '/api/user/address',
    method: 'get',
    params
  })
}

// 选择地址列表
export function setAddressList(data) {
  return request({
    url: '/user/address/list',
    method: 'put',
    data
  })
}
