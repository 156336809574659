import Vue from 'vue'
import VueAMap from 'vue-amap'

import { Sidebar, SidebarItem } from 'vant'
Vue.use(Sidebar)
Vue.use(SidebarItem)
Vue.use(VueAMap)
// A modern alternative to CSS resets
// https://github.com/necolas/normalize.css
import 'normalize.css/normalize.css'
import './styles/index.scss'

import App from './App.vue'
import router from './router'
import store from './store'

import './icons'
import './permission'

import * as filters from '@/filters'
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key])
})

// import vant
import '@/components/Vant'
// register common components globally
import '@/components/common'

VueAMap.initAMapApiLoader({
  key: '65fee072bcf5fa97fabcd2bec4dfbd33',
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor', 'AMap.Geolocation', 'MarkerClusterer'],
  // 默认高德 sdk 版本为 1.4.4版本
  v: '1.4.4'
})
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
