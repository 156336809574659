import axios from 'axios'
import { Toast } from 'vant'
import store from '@/store'
import { getToken, removeToken } from '@/utils/auth'

const config = require('./config')
const baseURL = config[process.env.NODE_ENV].baseUrl
console.log(baseURL, 'request')
const CancelToken = axios.CancelToken
const pendingReq = {}

// 创建一个axios实例
const service = axios.create({
  baseURL,
  // withCredentials: true,
  timeout: 99999
})

// 请求拦截器
service.interceptors.request.use(
  config => {
    if (store.getters.token) {
      // JWT鉴权
      console.log(getToken())
      console.log(localStorage.getItem('access_token'), '===============')
      if (getToken()) {
        config.headers.Authorization = `Bearer ${getToken()}`
      } else if (localStorage.getItem('access_token')) {
        config.headers.Authorization = `Bearer ${localStorage.getItem('access_token')}`
      }

      // 取消请求
      const key = config.url + '&' + config.method
      pendingReq[key] && pendingReq[key]('操作太频繁了~')
      config.cancelToken = new CancelToken(c => {
        pendingReq[key] = c
      })
    }
    // 授权来源 5 app uccs
    config.headers['x-auth-platform'] = 5
    return config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  response => {
    const res = response.data
    // 取消请求
    const key = response.config.url + '&' + response.config.method
    pendingReq[key] && delete pendingReq[key]

    console.log('response:', res.code, response)
    if (res.code === '401' || res.code === '2514') {
      removeToken()
      return Promise.reject(new Error(res.msg || 'Error'))
    }

    const token = response.headers.authorization
    if (token) {
      removeToken()

      const tmp = token.replace('Bearer ', '')
      store.dispatch('user/accessToken', tmp)
      return Promise.reject(new Error(response.data.msg || 'Error'))
    }

    // 与后端约定的错误码
    if (res.code === '401') {
      localStorage.getItem('access_token') && store.dispatch('user/resetToken')
      Toast.fail(response.data.msg)
      return Promise.reject(new Error(response.data.msg || 'Error'))
    } else {
      return res
    }
  },
  error => {
    Toast.fail('请求失败')
    return Promise.reject(error)
  }
)

export default service
