import { addProductCart, deleteProduct } from '@/api/order'
const state = {
  // 加入购物车的商品
  carts: []
}

const mutations = {
  // 添加商品到购物车
  ADD_PRODUCT(state, product) {
    const index = state.carts.findIndex(item => {
      return item.id === product.id
    })
    console.log(index)
    if (index > -1) {
      state.carts[index] = product
      console.log(index, state.carts[index])
      // return
    }
    state.carts.push(product)
  },
  MINUS_PRODUCT(state, product) {
    state.carts = product
  }
}
// 格式化商品数据用于提交订单
const handleCreateOrderData = (data) => {
  const { commercialId, quantity, id, supplyCondiments, attrs, materials_text } = data
  return {
    commercialId: commercialId,
    type: 1,
    quantity: quantity || 1,
    pid: id,
    properties: JSON.stringify(attrs),
    condiments: JSON.stringify(supplyCondiments)
  }
}
const actions = {
  async handleAddCart({ commit }, product) {
    await addProductCart(handleCreateOrderData(product)).then(res => {
      commit('ADD_PRODUCT', res.data)
    })
  },
  async handleMinusCart({ commit }, id) {
    await deleteProduct(id).then(res => {
      console.log(res)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
