import request from '@/utils/request'

// 获取订单列表
export function getOrderList(params) {
  return request({
    url: '/api/takeout',
    method: 'get',
    params
  })
}
// api/cart 添加购物车
export function addProductCart(data) {
  return request({
    url: '/api/cart',
    method: 'post',
    data
  })
}
// 删除购物车商品
export function deleteProduct(id) {
  return request({
    url: `/api/cart/${id}`,
    method: 'delete'
  })
}
// 清空购物车
export function clearCart(data) {
  return request({
    url: `/api/cart/clean/all`,
    method: 'delete',
    data
  })
}
// 获取购物车列表
export function getCartsList(params) {
  return request({
    url: '/api/cart',
    method: 'get',
    params
  })
}
// 创建订单
export function createOrder(data) {
  return request({
    url: '/api/takeout',
    method: 'post',
    data
  })
}
// 支付
export function payOrder(data) {
  return request({
    url: `api/takeout/${data.tpOrderId}/app-pay`,
    method: 'post',
    data
  })
}
// 订单详情
export function orderDetail(params) {
  return request({
    url: `api/takeout/${params.tpOrderId}`,
    method: 'get',
    params
  })
}
// 订单评价
export function addOrderEvaluation(data) {
  return request({
    url: 'api/order/evaluation',
    method: 'post',
    data
  })
}
// 门店评价列表
export function shopEvaluationList(params) {
  return request({
    url: 'api/order/evaluation',
    method: 'get',
    params
  })
}
// 评价图片上传
export function uploadEvaluatePic(data) {
  return request({
    url: 'api/order/evaluation/upload',
    method: 'post',
    data
  })
}
// 删除单个图片
export function deleteSingle(data) {
  return request({
    url: 'api/order/evaluation/delete-file',
    method: 'delete',
    data
  })
}
// 删除所有图片
export function deleteAllEvaluatePic(data) {
  return request({
    url: 'api/order/evaluation/delete-all-file',
    method: 'delete',
    data
  })
}
