module.exports = {
  mock: {
    baseUrl: '/dev-api',
    mockUrl: 'https://mock.xwhx.top'
  },
  local: {
    baseUrl: '/dev-api',
    mockUrl: 'http://localhost:3210'
  },
  development: {
    baseUrl: '/dev-api',
    mockUrl: 'http://honfeg-api.look56.com:88/'
  },
  production: {
    baseUrl: '/',
    mockUrl: 'http://honfeg-api.look56.com:88/'
  }
}
