<template>
  <div>
    <div class="tabbar">
      <van-tabbar v-model="active" :active-color="variables.theme" :fixed="false" route>
        <van-tabbar-item to="/home" icon="wap-home">
          首页
          <template #icon="props">
            <i class="iconfont icon-shouye" />
          </template>
        </van-tabbar-item>
        <van-tabbar-item to="/order/list" icon="bars">
          订单
          <template #icon="props">
            <i class="iconfont icon-dingdan" />
          </template>
        </van-tabbar-item>
        <van-tabbar-item to="/my" icon="manager">
          我的
          <template #icon="props">
            <i class="iconfont icon-wode" />
          </template>
        </van-tabbar-item>
      </van-tabbar>
    </div>
    <div class="tabbar--placeholder" style="width:100%;height:50px" />
  </div>
</template>

<script>
import variables from '@/styles/variables.scss'

export default {
  data() {
    return {
      active: 0
    }
  },
  computed: {
    variables() {
      return variables
    }
  }
}
</script>

<style lang="scss" scoped>
.tabbar {
  width: 100vw;
  position: fixed;
  bottom: 0;
  left: 0;
  border-top: 2px solid #f5f5f5;
  .iconfont{
    font-size: 100%;
  }
}
</style>
