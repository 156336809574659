import router from './router'
import store from './store'
import { Toast, Notify } from 'vant'
import { getToken } from '@/utils/auth' // get token from cookie
// import { blackList } from "./router/blacklist";

router.beforeEach(async(to, from, next) => {
  console.log(to, '=========================')
  // 设置标题
  document.title = to.meta.title || '美食'

  if (to.query.token) {
    localStorage.setItem('appToken', to.query.token)
    localStorage.setItem('cacode', to.query.cacode)
  }

  // 根据token判断用户是否登录
  const hasToken = getToken()

  console.log('hasToken:', hasToken)
  if (hasToken) {
    // 如果已经登录了，而去的又是login页就重定向到首页
    next()
  } else {
    !to.query.token && next()
    to.name !== 'Login' ? next({ name: 'Login', query: { ...to.query }}) : next()
  }
})

router.afterEach(() => {
  Toast.clear()
})
