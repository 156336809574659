import { login, getInfo, refreshLogin } from '@/api/user'
import { getToken, setToken, removeToken } from '@/utils/auth'
const state = {
  token: getToken(),
  uid: '',
  userInfo: {},
  address: {},
  shopInfo: {}
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_USER_INFO: (state, info) => {
    state.userInfo = info
    state.uid = info.uid
  },
  SET_ADDRESS: (state, address) => {
    console.log(address)
    state.address = address
  },
  SET_SHOPINFO: (state, info) => {
    console.log(info)
    state.shopInfo = info
  }
}

const actions = {
  async accessToken({ commit }, token) {
    return new Promise((resolve, reject) => {
      commit('SET_TOKEN', token)
      setToken(token)
      resolve()
    })
  },
  // 登录
  async login({ commit }, loginInfo) {
    return new Promise((resolve, reject) => {
      console.log('loginInfo:', loginInfo)
      login(loginInfo)
        .then(res => {
          console.log('loginInfo res:', res)
          const { access_token: token } = res.data.access_token
          commit('SET_TOKEN', token)
          setToken(token)
          localStorage.setItem('access_token', token)
          resolve()
        })
        .catch(error => {
          console.log('loginInfo error:', error)
          reject(error)
        })
    })
  },

  // 登出
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      commit('SET_TOKEN', '')
      removeToken()
      resolve()
    })
  },

  // 获取基本用户信息
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo(state.token)
        .then(res => {
          const data = res.entry
          if (!data) {
            reject(new Error('获取基本信息失败，请重新登录'))
          }
          commit('SET_USER_INFO', data)
          resolve(data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  // 重置token
  resetToken({ commit }) {
    return new Promise((resolve, reject) => {
      refreshLogin()
        .then(res => {
          const { access_token: token } = res.data.access_token
          commit('SET_TOKEN', token)
          setToken(token)
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
